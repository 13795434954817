import { Button, Modal, Form, Col, Row } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { GetUserData, AddUserData } from "../../axios/apis/Users";
import {
  GetCountries,
  GetStates,
  GetDistricts,
  GetTalukas,
  GetVillage,
} from "../../axios/apis/Location";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./addInfo.css";
import * as Yup from "yup";
import { Field, Formik } from "formik";
import Select from "react-select";
import keycloakService from "../../modules/index";

export default function AddInfo() {
  const [countrySelected, setCountrySelected] = useState(false);
  const [isSameAddressChanged, setIsSameAddressChanged] = useState(false);
  const [stateSelected, setStateSelected] = useState(false);
  const [districtSelected, setDistrictSelected] = useState(false);
  const [talukaSelected, setTalukaSelected] = useState(false);
  const [cStateSelected, setCStateSelected] = useState(false);
  const [cDistrictSelected, setCDistrictSelected] = useState(false);
  const [cTalukaSelected, setCTalukaSelected] = useState(false);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [genders, setGenders] = useState([]);
  const [familyIncome, setFamilyIncome] = useState([]);
  const [motherTongue, setMotherTongue] = useState([]);
  const [region, setRegion] = useState([]);
  const [religion, setReligion] = useState([]);
  const [maritalStatuses, setMaritalStatus] = useState([]);
  const [boolStatus, setBoolStatus] = useState([]);
  const [pwdType, setPwdType] = useState([]);
  const [cDistricts, setCDistricts] = useState([]);
  const [talukas, setTalukas] = useState([]);
  const [cTalukas, setCTalukas] = useState([]);
  const [villages, setVillages] = useState([]);
  const [cVillages, setCVillages] = useState([]);
  const [pTalukaNotApplicable, setPTalukaNotApplicable] = useState(false);
  const [cTalukaNotApplicable, setCTalukaNotApplicable] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [disableCAddress, setDisableCAddress] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [linguisticMinority, setLinguisticMinority] = useState([]);
  const [religiousMinority, setReligiousMinority] = useState([]);
  const [board, setBoard] = useState([]);
  const [marksType, setMarksType] = useState([]);
  const [requiredExtraTimeNotApplicable, setRequiredExtraTimeNotApplicable] =
    useState(false);
  const [requiredScribeNotApplicable, setRequiredScribeNotApplicable] =
    useState(false);
  const [hscPercentageDisabled, setHscPercentageDisabled] = useState(false);
  const [decimalRegex] = useState(/^\d*\.?\d{0,2}$/);
  const [pVillageNotApplicable, setPVillageNotApplicable] = useState(false);
  const [cVillageNotApplicable, setCVillageNotApplicable] = useState(false);

  const selectStyle = {
    control: (baseStyles, { isDisabled }) => ({
      ...baseStyles,
      minHeight: "45px",
      borderColor: "#dddddd",
      backgroundColor: isDisabled ? "#e9ecef" : "#FFF",
    }),
    placeholder: (baseStyles, { isDisabled }) => ({
      ...baseStyles,
      color: "#545454",
    }),
  };
  let patternTwoDigitsAfterComma = /^\d+(\.\d{0,2})?$/;
  const validationSchema = Yup.object().shape({
    fatherName: Yup.string()
      .matches(/^[a-zA-Z '`.-]+$/, "Name should only contain alphabets")
      .required("Father name is required"),
    motherName: Yup.string()
      .matches(/^[a-zA-Z '`.-]+$/, "Name should only contain alphabets")
      .required("Mother name is required"),
    gender: Yup.string().required("Gender is required"),
    mobileNo: Yup.string()
    .matches(/^[0-9]{10,10}$/, "Please enter a valid number")
    .required("Phone no is required"),
    religion: Yup.string().required("Religion is required"),
    region: Yup.string().required("Region is required"),
    motherTongue: Yup.string().required("Mother tongue is required"),
    nationality: Yup.string().required("Nationality is required"),
    annualFamilyIncome: Yup.string().required("Annual income is required"),
    maritalStatus: Yup.string().required("Marital status is required"),
    spouseName: Yup.string().when(["maritalStatus"], {
      is: (maritalStatus) => maritalStatus === "Married",
      then: (schema) => schema.required("Spouse name is required"),
      otherwise: (schema) => schema.nullable(),
    }),
    pAddressLine1: Yup.string().required("AddressLine1 is required"),
    pAddressLine2: Yup.string().required("AddressLine2 is required"),
    pAddressLine3: Yup.string().nullable(),
    pState: Yup.string().required("State is required"),
    pDistrict: Yup.string().required("District is required"),
    pTaluka: Yup.string().when([], {
      is: () => pTalukaNotApplicable === true,
      then: (schema) => schema.nullable(),
      otherwise: (schema) => schema.required("Taluka is required"),
    }),
    pVillage: Yup.string().when([], {
      is: () => pVillageNotApplicable === true,
      then: (schema) => schema.nullable(),
      otherwise: (schema) => schema.required("Village is required"),
    }),
    pPincode: Yup.string()
      .matches(/^[0-9]{6,6}$/, "Please enter a 6-digit numeric PIN Code only")
      .required("Pincode is required"),
    stdCode: Yup.string()
      .matches(/^\d+$/, "Please enter a valid number")
      .nullable(),
    phoneNo: Yup.string()
      .matches(/^[0-9]{10,10}$/, "Please enter a valid number")
      .required("Phone no is required"),
    cAddressLine1: Yup.string().required("AddressLine1 is required"),
    cAddressLine2: Yup.string().required("AddressLine2 is required"),
    cAddressLine3: Yup.string().nullable(),
    cState: Yup.string().required("State is required"),
    cDistrict: Yup.string().required("District is required"),
    cPincode: Yup.string()
      .matches(/^[0-9]{6,6}$/, "Please enter a 6-digit numeric PIN Code only")
      .required("Pincode is required"),
    cTaluka: Yup.string().when([], {
      is: () => cTalukaNotApplicable === true,
      then: (schema) => schema.nullable(),
      otherwise: (schema) => schema.required("Taluka is required"),
    }),
    cVillage: Yup.string().when([], {
      is: () => cVillageNotApplicable === true,
      then: (schema) => schema.nullable(),
      otherwise: (schema) => schema.required("Village is required"),
    }),
    isMhCandidate: Yup.string().required("Are you Domiciled in the State of Maharashtra is required"),
    appliedForDisabilityStatus: Yup.string().required("Are you Person With Disability is required"),
    pwdType: Yup.string().when(["appliedForDisabilityStatus"], {
      is: (appliedForDisabilityStatus) => appliedForDisabilityStatus === "Yes",
      then: (schema) => schema.required("Type of disability is required"),
      otherwise: (schema) => schema.nullable(),
    }),
    isMinority: Yup.string().when(["isMhCandidate"], {
      is: (isMhCandidate) => isMhCandidate === "Yes",
      then: (schema) => schema.required("Minority is required"),
      otherwise: (schema) => schema.nullable(),
    }),
    linguisticMinority: Yup.string()
      .when(["isMinority"], {
        is: (isMinority) => isMinority === "Yes",
        then: (schema) => schema.required("Linguistic minority is required"),
        otherwise: (schema) => schema.nullable(),
      })
      .when(["religiousMinority"], {
        is: (religiousMinority) => religiousMinority === "NA",
        then: (schema) =>
          schema.test(
            "is-religious-minority",
            "Both Religious minority and Linguistic minority cannot be NA",
            (val) => {
              if (val !== undefined) {
                return !(val === "NA");
              }
              return true;
            }
          ),
      }),
    religiousMinority: Yup.string().when(["isMinority"], {
      is: (isMinority) => isMinority === "Yes",
      then: (schema) => schema.required("Religious minority is required"),
      otherwise: (schema) => schema.nullable(),
    }),
    sscPassingYear: Yup.string()
      .matches(/^\d+$/, "Please enter a valid number")
      .required("SSC passing year is required"),
    sscTotalPercentage: Yup.number()
      .test(
        "is-decimal",
        "The percentage should be a decimal with maximum two digits",
        (val) => {
          if (val !== undefined) {
            return patternTwoDigitsAfterComma.test(val);
          }
          return true;
        }
      )
      .required("SSC total percentage is required")
      .typeError("Please enter a valid number")
      .min(0, "SSC total percentage must be at least 0")
      .max(100, "SSC total percentage cannot be more than 100"),
    sscBoard: Yup.string().required("SSC board is required"),
    otherSscBoard: Yup.string().when(["sscBoard"], {
      is: (sscBoard) => sscBoard == 99,
      then: (schema) => schema.required("Other SSC board is required"),
      otherwise: (schema) => schema.nullable(),
    }),
    sscSchoolState: Yup.string().required("SSC school state is required"),
    hscPassingStatus: Yup.string().required("HSC passing status is required"),
    hscPassingYear: Yup.string().when(["hscPassingStatus"], {
      is: (hscPassingStatus) => hscPassingStatus === "No",
      then: (schema) =>
        schema
          .matches(/^\d+$/, "Please enter a valid number")
          .when(["sscPassingYear"], (sscPassingYear, schema) =>
            schema.test(
              "is-greater-than-ssc",
              "HSC/Diploma passing year must be greater than SSC passing year",
              function (hscPassingYear) {
                return hscPassingYear > sscPassingYear;
              }
            )
          )
          .required("HSC/Diploma passing year is required"),
      otherwise: (schema) => schema.nullable(),
    }),
    hscBoard: Yup.string().when(["hscPassingStatus"], {
      is: (hscPassingStatus) => hscPassingStatus === "No",
      then: (schema) => schema.required("HSC/Diploma board is required"),
      otherwise: (schema) => schema.nullable(),
    }),
    otherHscBoard: Yup.string().when(["hscBoard"], {
      is: (hscBoard) => hscBoard == 99,
      then: (schema) => schema.required("Other HSC/Diploma board is required"),
      otherwise: (schema) => schema.nullable(),
    }),
    hscMarksObtained: Yup.number()
      .when(["hscPassingStatus"], {
        is: (hscPassingStatus) => hscPassingStatus === "No",
        then: (schema) =>
          schema.required("HSC/Diploma marks obtained is required"),
        otherwise: (schema) => schema.nullable(),
      })
      .integer("Enter whole numbers only for HSC/Diploma marks obtained")
      .typeError("Please enter a valid number")
      .when(["hscMarksOutOf"], {
        is: (hscMarksOutOf) => hscMarksOutOf && hscMarksOutOf > 0,
        then: (schema) =>
          schema.max(
            Yup.ref("hscMarksOutOf"),
            "HSC/Diploma Marks Obtained must not exceed HSC/Diploma Marks Out Of"
          ),
      }),
    hscMarksOutOf: Yup.number()
      .when(["hscPassingStatus"], {
        is: (hscPassingStatus) => hscPassingStatus === "No",
        then: (schema) =>
          schema.required("HSC/Diploma marks out of is required"),
        otherwise: (schema) => schema.nullable(),
      })
      .integer("Enter whole numbers only for HSC/Diploma marks out of")
      .typeError("Please enter a valid number"),
    hscPercentage: Yup.number()
      .when(["hscPassingStatus"], {
        is: (hscPassingStatus) => hscPassingStatus === "No",
        then: (schema) =>
          schema
            .required("HSC/Diploma percentage is required")
            .test(
              "is-decimal",
              "The percentage should be a decimal with maximum two digits",
              (val) => {
                if (val !== undefined) {
                  return patternTwoDigitsAfterComma.test(val);
                }
                return true;
              }
            ),
        otherwise: (schema) => schema.nullable(),
      })
      .typeError("Please enter a valid number")
      .min(0, "HSC/Diploma percentage must be at least 0")
      .max(100, "HSC/Diploma percentage cannot be more than 100"),
    hscMarksType: Yup.string().when(["hscPassingStatus"], {
      is: (hscPassingStatus) => hscPassingStatus === "No",
      then: (schema) => schema.required("HSC/Diploma marks type is required"),
      otherwise: (schema) => schema.nullable(),
    }),
  });

  const initialValues = {
    fatherName: null,
    motherName: null,
    gender: null,
    mobileNo: null,
    religion: null,
    region: null,
    motherTongue: null,
    nationality: 96,
    annualFamilyIncome: null,
    maritalStatus: null,
    spouseName: null,
    pAddressLine1: null,
    pAddressLine2: null,
    pAddressLine3: null,
    pState: null,
    pDistrict: null,
    pTaluka: null,
    pVillage: null,
    pPincode: null,
    stdCode: null,
    phoneNo: null,
    cAddressLine1: null,
    cAddressLine2: null,
    cAddressLine3: null,
    cState: null,
    cDistrict: null,
    cTaluka: null,
    cVillage: null,
    cPincode: null,
    isMhCandidate: null,
    appliedForDisabilityStatus: null,
    pwdType: null,
    requireExtraTime: null,
    requireScribe: null,
    isMinority: null,
    linguisticMinority: null,
    religiousMinority: null,
    sscPassingYear: null,
    sscTotalPercentage: null,
    sscBoard: null,
    otherSscBoard: null,
    sscSchoolState: null,
    hscPassingStatus: null,
    hscPassingYear: null,
    hscBoard: null,
    otherHscBoard: null,
    hscMarksType: null,
    hscMarksObtained: null,
    hscMarksOutOf: null,
    hscPercentage: null,
  };

  useEffect(() => {
    GetUserData()
      .then((result) => {
        setShowModal(false);
      })
      .catch((error) => {
        if (error.status === 404) {
          setShowModal(true);
        } else {
          toast.error("Something went wrong!");
          keycloakService.doLogout();
        }
      });

    GetCountries()
      .then((result) => {
        setCountries(result.nationality);
        setGenders(result.gender);
        setFamilyIncome(result.familyIncome);
        setMotherTongue(result.motherTongue);
        setRegion(result.region);
        setReligion(result.religion);
        setMaritalStatus(result.maritalStatus);
        setBoolStatus(result.boolStatus);
        setPwdType(result.pwdType.sort());
        setLinguisticMinority(result.linguisticMinority);
        setReligiousMinority(result.religiousMinority);
        setBoard(result.board);
        setMarksType(result.marksType);
      })
      .catch((error) => {});
    getStatesData();
  }, []);
  const getStatesData = (country) => {
    GetStates(country)
      .then((result) => {
        setStates(result);
      })
      .catch((error) => {});
  };

  const getDistrictsData = (state) => {
    setDistricts([]);
    setTalukas([]);
    setVillages([]);
    GetDistricts(state)
      .then((result) => {
        const sortedDistrict = result.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        setDistricts(sortedDistrict);
      })
      .catch((error) => {});
  };

  const getCDistrictsData = (state) => {
    setCDistricts([]);
    setCTalukas([]);
    setCVillages([]);
    GetDistricts(state)
      .then((result) => {
        const sortedDistrict = result.sort((a, b) =>
          a.name.localeCompare(b.name)
        );
        setCDistricts(sortedDistrict);
      })
      .catch((error) => {});
  };

  const getTalukasData = (district) => {
    GetTalukas(Number(district))
      .then((result) => {
        if (
          result.length === 0 ||
          (result.length === 1 && result[0].name === "Not Applicable")
        ) {
          setPTalukaNotApplicable(true);
          setPVillageNotApplicable(true);
        } else {
          setPTalukaNotApplicable(false);
          setPVillageNotApplicable(false);
          const sortedTalukas = result.sort((a, b) =>
            a.name.localeCompare(b.name)
          );
          setTalukas(sortedTalukas);
        }
      })
      .catch((error) => {});
  };

  const getCTalukasData = (district) => {
    GetTalukas(district)
      .then((result) => {
        if (
          result.length === 0 ||
          (result.length === 1 && result[0].name === "Not Applicable")
        ) {
          setCTalukaNotApplicable(true);
          setCVillageNotApplicable(true);
        } else {
          setCTalukaNotApplicable(false);
          setCVillageNotApplicable(false);
          const sortedTalukas = result.sort((a, b) =>
            a.name.localeCompare(b.name)
          );
          setCTalukas(sortedTalukas);
        }
      })
      .catch((error) => {});
  };

  const getVillagesData = (taluka) => {
    GetVillage(taluka)
      .then((result) => {
        if (
          result.length === 0 ||
          (result.length === 1 && result[0].name === "Not Applicable")
        ) {
          setPVillageNotApplicable(true);
        } else {
          setPVillageNotApplicable(false);
          const sortedVillages = result.sort((a, b) =>
            a.name.localeCompare(b.name)
          );
          setVillages(sortedVillages);
        }
      })
      .catch((error) => {});
  };

  const getCVillagesData = (taluka) => {
    GetVillage(taluka)
      .then((result) => {
        if (
          result.length === 0 ||
          (result.length === 1 && result[0].name === "Not Applicable")
        ) {
          setCVillageNotApplicable(true);
        } else {
          setCVillageNotApplicable(false);
          const sortedVillages = result.sort((a, b) =>
            a.name.localeCompare(b.name)
          );
          setCVillages(sortedVillages);
        }
      })
      .catch((error) => {});
  };

  const handleCheckboxChange = (e, values) => {
    const checked = e.target.checked;
    setIsChecked(checked);
    if (checked) {
      setCDistricts([...districts]);
      setCTalukas([...talukas]);
      setCVillages([...villages]);
      values.cAddressLine1 = values.pAddressLine1;
      values.cAddressLine2 = values.pAddressLine2;
      values.cAddressLine3 = values.pAddressLine3 || "";
      values.cState = values.pState;
      values.cDistrict = values.pDistrict;
      values.cTaluka = values.pTaluka;
      values.cVillage = values.pVillage;
      values.cPincode = values.pPincode;
      setDisableCAddress(true);
      if (pTalukaNotApplicable) {
        setCTalukaNotApplicable(true);
      }
      if (pVillageNotApplicable) {
        setCVillageNotApplicable(true);
      } else {
        setCVillageNotApplicable(false);
      }
      setCountrySelected(false);
      setCStateSelected(false);
      setCDistrictSelected(false);
      setCTalukaSelected(false);
      setIsSameAddressChanged(false);
    } else {
      setCDistricts([]);
      setCTalukas([]);
      setCVillages([]);
      values.cAddressLine1 = "";
      values.cAddressLine2 = "";
      values.cAddressLine3 = "";
      values.cState = "";
      values.cDistrict = "";
      values.cTaluka = "";
      values.cVillage = "";
      values.cPincode = "";
      setDisableCAddress(false);
      setCTalukaNotApplicable(false);
      // setCountrySelected(true);
      // setCStateSelected(true);
      setCDistrictSelected(true);
      setCTalukaSelected(true);
      setIsSameAddressChanged(true);
      setCVillageNotApplicable(false);
    }
  };

  const handleAddress = (values) => {
    if (isChecked) {
      setIsChecked(false);
      setCDistricts([]);
      setCTalukas([]);
      setCVillages([]);
      values.cAddressLine1 = "";
      values.cAddressLine2 = "";
      values.cAddressLine3 = "";
      values.cState = "";
      values.cDistrict = "";
      values.cTaluka = "";
      values.cVillage = "";
      values.cPincode = "";
      setDisableCAddress(false);
      setCTalukaNotApplicable(false);
      setCDistrictSelected(true);
      setCTalukaSelected(true);
      setCVillageNotApplicable(false);
      setIsSameAddressChanged(true);
    }
  };
  const handlePAddress = (values) => {
    values.pDistrict = "";
    values.pTaluka = "";
    values.pVillage = "";
  };
  const handleCAddress = (values) => {
    values.cDistrict = "";
    values.cTaluka = "";
    values.cVillage = "";
  };

  const submitForm = (values, action) => {
    const payload = {
      fatherName: values.fatherName,
      motherName: values.motherName,
      gender: values.gender,
      mobileNo: values.mobileNo,
      religion: values.religion,
      region: values.region,
      motherTongue: values.motherTongue,
      nationality: values.nationality,
      annualFamilyIncome: values.annualFamilyIncome,
      maritalStatus: values.maritalStatus ? values.maritalStatus : null,
      spouseName:
        values.maritalStatus === "Married"
          ? values.spouseName
            ? values.spouseName
            : null
          : null,
      pAddressLine1: values.pAddressLine1,
      pAddressLine2: values.pAddressLine2,
      pAddressLine3: values.pAddressLine3 ? values.pAddressLine3 : null,
      pState: values.pState,
      pDistrict: values.pDistrict,
      pTaluka: pTalukaNotApplicable
        ? null
        : values.pTaluka
        ? values.pTaluka
        : null,
      pVillage: pVillageNotApplicable
        ? null
        : values.pVillage
        ? values.pVillage
        : null,
      pPincode: values.pPincode,
      stdCode: values.stdCode ? values.stdCode : null,
      phoneNo: values.phoneNo,
      cAddressLine1: values.cAddressLine1,
      cAddressLine2: values.cAddressLine2,
      cAddressLine3: values.cAddressLine3 ? values.cAddressLine3 : null,
      cState: values.cState,
      cDistrict: values.cDistrict,
      cTaluka: cTalukaNotApplicable
        ? null
        : values.cTaluka
        ? values.cTaluka
        : null,
      cVillage: cVillageNotApplicable
        ? null
        : values.cVillage
        ? values.cVillage
        : null,
      cPincode: values.cPincode,
      isMhCandidate: values.isMhCandidate ? values.isMhCandidate : null,
      appliedForDisabilityStatus: values.appliedForDisabilityStatus
        ? values.appliedForDisabilityStatus
        : null,
      pwdType:
        values.appliedForDisabilityStatus === "Yes"
          ? values.pwdType
            ? values.pwdType
            : null
          : null,
      requireExtraTime:
        values.appliedForDisabilityStatus === "Yes"
          ? values.pwdType !== null
            ? values.requireExtraTime
              ? values.requireExtraTime
              : null
            : null
          : null,
      requireScribe:
        values.appliedForDisabilityStatus === "Yes"
          ? values.pwdType !== null
            ? values.requireScribe
              ? values.requireScribe
              : null
            : null
          : null,
      isMinority:
        values.isMhCandidate === "Yes"
          ? values.isMinority
            ? values.isMinority
            : null
          : null,
      linguisticMinority:
        values.isMhCandidate === "Yes"
          ? values.isMinority === "Yes"
            ? values.linguisticMinority
              ? values.linguisticMinority
              : null
            : null
          : null,
      religiousMinority:
        values.isMhCandidate === "Yes"
          ? values.isMinority === "Yes"
            ? values.religiousMinority
              ? values.religiousMinority
              : null
            : null
          : null,
      sscPassingYear: values.sscPassingYear ? values.sscPassingYear : null,
      sscTotalPercentage: values.sscTotalPercentage
        ? values.sscTotalPercentage
        : null,
      sscBoard: values.sscBoard ? values.sscBoard : null,
      otherSscBoard:
        values.sscBoard === 99
          ? values.otherSscBoard
            ? values.otherSscBoard
            : null
          : null,
      sscSchoolState: values.sscSchoolState ? values.sscSchoolState : null,
      hscPassingStatus: values.hscPassingStatus
        ? values.hscPassingStatus
        : null,
      hscPassingYear:
        values.hscPassingStatus === "No"
          ? values.hscPassingYear
            ? values.hscPassingYear
            : null
          : null,
      hscBoard:
        values.hscPassingStatus === "No"
          ? values.hscBoard
            ? values.hscBoard
            : null
          : null,
      otherHscBoard:
        values.hscPassingStatus === "No"
          ? values.hscBoard === 99
            ? values.otherHscBoard
              ? values.otherHscBoard
              : null
            : null
          : null,
      hscMarksObtained:
        values.hscPassingStatus === "No"
          ? values.hscMarksObtained
            ? values.hscMarksObtained
            : null
          : null,
      hscMarksOutOf:
        values.hscPassingStatus === "No"
          ? values.hscMarksOutOf
            ? values.hscMarksOutOf
            : null
          : null,
      hscPercentage:
        values.hscPassingStatus === "No"
          ? values.hscPercentage
            ? values.hscPercentage
            : null
          : null,
      isSameAddress: isChecked,
      hscMarksType:
        values.hscPassingStatus === "No" ? values.hscMarksType : null,
    };
    AddUserData(payload)
      .then((result) => {
        toast.success("Data updated successfully!");
        setShowModal(false);
      })
      .catch((error) => {
        if (error.status === 500) {
          toast.error("Something went wrong!");
        } else if (error.status === 400) {
          toast.error(error.data.error);
        }
      });
  };

  const getScribeAndExtraTime = (pwd, fieldName) => {
    const fieldState =
      fieldName === "requireExtraTime"
        ? setRequiredExtraTimeNotApplicable
        : setRequiredScribeNotApplicable;
    switch (pwd) {
      case "Cerebral Palsy":
        fieldState(true);
        return "Yes";
      case "Dwarfism":
        fieldState(true);
        return "No";
      case "Acid Attack Victims":
        fieldState(true);
        return "No";
      case "Blindness":
        fieldState(true);
        return "Yes";
      case "Low Vision":
        fieldState(true);
        return "Yes";
      case "Deaf":
        fieldState(true);
        return "No";
      case "Hard of Hearing":
        fieldState(true);
        return "No";
      case "Multiple Sclerosis":
        fieldState(true);
        return "Yes";
      case "Parkinsons Disease":
        fieldState(true);
        return "Yes";
      case "Haemophilia":
        fieldState(true);
        return "No";
      case "Thalassemia":
        fieldState(true);
        return "No";
      case "Sickle Cell Disease":
        fieldState(true);
        return "No";
      default:
        fieldState(false);
        return null;
    }
  };

  const handleFilterChange = (candidate, input) => {
    if (input) {
      return candidate.label.toLowerCase().startsWith(input.toLowerCase());
    }
    return true;
  };

  const startYear = 1945;
  const currentYear = new Date().getFullYear();
  const maxYear = 2023;

  //HSC Years
  const years = Array.from(
    { length: currentYear - startYear + 1 },
    (_, index) => currentYear - index
  );

  //SSC Years
  const effectiveMaxYear = Math.min(currentYear, maxYear);

  const sscYears = Array.from(
    { length: effectiveMaxYear - startYear + 1 },
    (_, index) => effectiveMaxYear - index
  );

  const calculatePercentage = (values, marks, fullMarks) => {
    const hscMarksObtained = parseFloat(marks);
    const hscMarksOutOf = parseFloat(fullMarks);
    if (
      !isNaN(hscMarksObtained) &&
      !isNaN(hscMarksOutOf) &&
      hscMarksOutOf !== 0
    ) {
      const calculatedPercentage = (hscMarksObtained / hscMarksOutOf) * 100;
      return calculatedPercentage.toFixed(2);
    }
  };

  const disableHscPercentageField = (value) => {
    if (value === "Percentage") {
      setHscPercentageDisabled(true);
    } else {
      setHscPercentageDisabled(false);
    }
  };
  return (
    <>
      <Formik
        onSubmit={submitForm}
        initialValues={initialValues}
        validationSchema={validationSchema}
      >
        {({
          values,
          errors,
          isValid,
          touched,
          setFieldTouched,
          isSubmitting,
          setFieldValue,
          handleSubmit,
        }) => {
          if (isSubmitting) {
            const firstErrorField = Object.keys(errors)[0];
            const fieldElement = document.getElementById(firstErrorField);
            if (fieldElement) {
              fieldElement.scrollIntoView({
                behavior: "smooth",
                block: "center",
              });
            }
          }

          return (
            <Form>
              <Modal size="xl" show={showModal} scrollable>
                <Modal.Header>
                  <Modal.Title as="h4" className="lh-sm">
                    User Info
                  </Modal.Title>
                  <Modal.Title as="small" className="text-muted lh-sm">
                    <sup className="text-danger">*</sup> Marked fields are
                    required
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <h3 className="fs-5 fw-normal text-primary mb-3 mb-md-4">
                    Personal Details
                  </h3>
                  <Row className="row-gap-3 row-gap-md-4 mb-5 mb-md-6 mb-lg-7">
                  <Col xs={12} md={6}>
                      <Form.Group controlId="mobileNo">
                        <Form.Label className="d-block mb-2 lh-1 fw-semibold fs-7">
                          Mobile No<sup className="text-danger">*</sup>
                        </Form.Label>
                        <Field
                          className="form-control"
                          type="text"
                          placeholder="Mobile No"
                          name="mobileNo"
                          id="mobileNo"
                          value={values.mobileNo}
                        />
                        <Form.Text>
                          Please ensure the entered mobile number is correct as
                          the OTP verification will take place on this mobile
                          number.
                        </Form.Text>
                        {errors.mobileNo && touched.mobileNo ? (
                          <Form.Text className="text-danger fs-8" as="div">
                            {errors.mobileNo}
                          </Form.Text>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group controlId="fatherName">
                        <Form.Label className="d-block mb-2 lh-1 fw-semibold fs-7">
                          Father's Name (First Name Only)
                          <sup className="text-danger">*</sup>
                        </Form.Label>
                        <Field
                          id="fatherName"
                          className="form-control upper-case"
                          type="text"
                          placeholder="Father's Name (First Name Only)"
                          name="fatherName"
                          value={values.fatherName}
                          onChange={(event) =>
                            setFieldValue(
                              "fatherName",
                              event.target.value.toUpperCase()
                            )
                          }
                        />
                        <Form.Text>
                          In case of Blank/Not Applicable Fields, the candidate
                          must enter '.' (DOT)
                        </Form.Text>
                        {errors.fatherName && touched.fatherName ? (
                          <Form.Text className="text-danger fs-8 mt-0" as="div">
                            {errors.fatherName}
                          </Form.Text>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group controlId="motherName">
                        <Form.Label className="d-block mb-2 lh-1 fw-semibold fs-7">
                          Mother's Name (First Name Only)
                          <sup className="text-danger">*</sup>
                        </Form.Label>
                        <Field
                          id="motherName"
                          className="form-control upper-case"
                          type="text"
                          placeholder="Mother's Name (First Name Only)"
                          name="motherName"
                          value={values.motherName}
                          onChange={(event) =>
                            setFieldValue(
                              "motherName",
                              event.target.value.toUpperCase()
                            )
                          }
                        />
                        <Form.Text>
                          In case of Blank/Not Applicable Fields, the candidate
                          must enter '.' (DOT)
                        </Form.Text>
                        {errors.motherName && touched.motherName ? (
                          <Form.Text className="text-danger fs-8 mt-0" as="div">
                            {errors.motherName}
                          </Form.Text>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group controlId="gender">
                        <Form.Label className="d-block mb-2 lh-1 fw-semibold fs-7">
                          Gender<sup className="text-danger">*</sup>
                        </Form.Label>
                        <Select
                          styles={selectStyle}
                          name="gender"
                          id="gender"
                          placeholder="Select Gender"
                          options={genders.map((gender) => ({
                            value: gender,
                            label: gender,
                          }))}
                          value={genders.find(
                            (g) => (g === g.value) === values.gender
                          )}
                          onChange={(selectedOption) =>
                            setFieldValue("gender", selectedOption.value)
                          }
                        ></Select>
                        {errors.gender && touched.gender ? (
                          <Form.Text className="text-danger fs-8" as="div">
                            {errors.gender}
                          </Form.Text>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group controlId="religion">
                        <Form.Label className="d-block mb-2 lh-1 fw-semibold fs-7">
                          Religion<sup className="text-danger">*</sup>
                        </Form.Label>
                        <Select
                          styles={selectStyle}
                          name="religion"
                          id="religion"
                          placeholder="Select Religion"
                          options={religion.map((religion) => ({
                            value: religion,
                            label: religion,
                          }))}
                          filterOption={handleFilterChange}
                          value={religion.find(
                            (g) => (g === g.value) === values.religion
                          )}
                          onChange={(selectedOption) =>
                            setFieldValue("religion", selectedOption.value)
                          }
                        ></Select>
                        {errors.religion && touched.religion ? (
                          <Form.Text className="text-danger fs-8" as="div">
                            {errors.religion}
                          </Form.Text>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group controlId="region">
                        <Form.Label className="d-block mb-2 lh-1 fw-semibold fs-7">
                          Region<sup className="text-danger">*</sup>
                        </Form.Label>
                        <Select
                          styles={selectStyle}
                          name="region"
                          id="region"
                          placeholder="Select Region"
                          options={region.map((region) => ({
                            value: region,
                            label: region,
                          }))}
                          value={region.find(
                            (g) => (g === g.value) === values.religion
                          )}
                          onChange={(selectedOption) =>
                            setFieldValue("region", selectedOption.value)
                          }
                        ></Select>
                        {errors.region && touched.region ? (
                          <Form.Text className="text-danger fs-8" as="div">
                            {errors.region}
                          </Form.Text>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group controlId="motherTongue">
                        <Form.Label className="d-block mb-2 lh-1 fw-semibold fs-7">
                          Mother Tongue<sup className="text-danger">*</sup>
                        </Form.Label>
                        <Select
                          styles={selectStyle}
                          name="motherTongue"
                          id="motherTongue"
                          placeholder="Select Mother Tongue"
                          options={motherTongue.map((motherTongue) => ({
                            value: motherTongue,
                            label: motherTongue,
                          }))}
                          filterOption={handleFilterChange}
                          value={motherTongue.find(
                            (g) => g.value === values.motherTongue
                          )}
                          onChange={(selectedOption) =>
                            setFieldValue("motherTongue", selectedOption.value)
                          }
                        ></Select>
                        {errors.motherTongue && touched.motherTongue ? (
                          <Form.Text className="text-danger fs-8" as="div">
                            {errors.motherTongue}
                          </Form.Text>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group controlId="nationality">
                        <Form.Label className="d-block mb-2 lh-1 fw-semibold fs-7">
                          Nationality<sup className="text-danger">*</sup>
                        </Form.Label>
                        <Select
                          styles={selectStyle}
                          name="nationality"
                          id="nationality"
                          placeholder="Select Nationality"
                          options={countries}
                          value={countries.find(
                            (option) => option.id === values.nationality
                          )}
                          filterOption={handleFilterChange}
                          onChange={(selectedOption) => {
                            getStatesData(selectedOption.id);
                            setPTalukaNotApplicable(false);
                            setCTalukaNotApplicable(false);
                            setPVillageNotApplicable(false);
                            setCVillageNotApplicable(false);
                            setCountrySelected(true);
                            setFieldValue("nationality", selectedOption.id);
                          }}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                        ></Select>
                        {errors.nationality && touched.nationality ? (
                          <Form.Text className="text-danger fs-8" as="div">
                            {errors.nationality}
                          </Form.Text>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group controlId="annualFamilyIncome">
                        <Form.Label className="d-block mb-2 lh-1 fw-semibold fs-7">
                          Annual Family Income
                          <sup className="text-danger">*</sup>
                        </Form.Label>
                        <Select
                          styles={selectStyle}
                          name="annualFamilyIncome"
                          id="annualFamilyIncome"
                          placeholder="Select Income"
                          options={familyIncome.map((annualFamilyIncome) => ({
                            value: annualFamilyIncome,
                            label: annualFamilyIncome,
                          }))}
                          value={familyIncome.find(
                            (g) => g.value === values.annualFamilyIncome
                          )}
                          onChange={(selectedOption) =>
                            setFieldValue(
                              "annualFamilyIncome",
                              selectedOption.value
                            )
                          }
                        ></Select>
                        {errors.annualFamilyIncome &&
                        touched.annualFamilyIncome ? (
                          <Form.Text className="text-danger fs-8" as="div">
                            {errors.annualFamilyIncome}
                          </Form.Text>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group controlId="maritalStatus">
                        <Form.Label className="d-block mb-2 lh-1 fw-semibold fs-7">
                          Marital Status
                          <sup className="text-danger">*</sup>
                        </Form.Label>
                        <Select
                          styles={selectStyle}
                          name="maritalStatus"
                          id="maritalStatus"
                          placeholder="Select Marital Status"
                          options={maritalStatuses.map((marital) => ({
                            value: marital,
                            label: marital,
                          }))}
                          value={maritalStatuses.find(
                            (g) => g.value === values.maritalStatus
                          )}
                          onChange={(selectedOption) =>
                            setFieldValue("maritalStatus", selectedOption.value)
                          }
                        ></Select>
                        {errors.maritalStatus && touched.maritalStatus ? (
                          <Form.Text className="text-danger fs-8" as="div">
                            {errors.maritalStatus}
                          </Form.Text>
                        ) : null}
                      </Form.Group>
                    </Col>
                    {values.maritalStatus === "Married" ? (
                      <Col xs={12} md={6}>
                        <Form.Group controlId="spouseName">
                          <Form.Label className="d-block mb-2 lh-1 fw-semibold fs-7">
                            Spouse Name
                            <sup className="text-danger">*</sup>
                          </Form.Label>
                          <Field
                            className="form-control upper-case"
                            type="text"
                            placeholder="Spouse Name"
                            name="spouseName"
                            id="spouseName"
                            value={values.spouseName}
                            onChange={(event) =>
                              setFieldValue(
                                "spouseName",
                                event.target.value.toUpperCase()
                              )
                            }
                          />
                          {errors.spouseName && touched.spouseName ? (
                            <Form.Text className="text-danger fs-8" as="div">
                              {errors.spouseName}
                            </Form.Text>
                          ) : null}
                        </Form.Group>
                      </Col>
                    ) : (
                      ""
                    )}
                  </Row>

                  <h3 className="fs-5 fw-normal text-primary mb-3 mb-md-4">
                    Permanent Address
                  </h3>
                  <Row className="row-gap-3 row-gap-md-4 mb-5 mb-md-6 mb-lg-7">
                    <Col xs={12} md={6}>
                      <Form.Group controlId="pAddressLine1">
                        <Form.Label className="d-block mb-2 lh-1 fw-semibold fs-7">
                          Address Line 1<sup className="text-danger">*</sup>
                        </Form.Label>
                        <Field
                          className="form-control"
                          type="text"
                          placeholder="Address Line 1"
                          name="pAddressLine1"
                          id="pAddressLine1"
                          value={values.pAddressLine1}
                          onChange={(e) => {
                            handleAddress(values);
                            setFieldValue("pAddressLine1", e.target.value);
                          }}
                        />
                        {errors.pAddressLine1 && touched.pAddressLine1 ? (
                          <Form.Text className="text-danger fs-8" as="div">
                            {errors.pAddressLine1}
                          </Form.Text>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group controlId="pAddressLine2">
                        <Form.Label className="d-block mb-2 lh-1 fw-semibold fs-7">
                          Address Line 2<sup className="text-danger">*</sup>
                        </Form.Label>
                        <Field
                          className="form-control"
                          type="text"
                          placeholder="Address Line 2"
                          name="pAddressLine2"
                          id="pAddressLine2"
                          value={values.pAddressLine2}
                          onChange={(e) => {
                            handleAddress(values);
                            setFieldValue("pAddressLine2", e.target.value);
                          }}
                        />
                        {errors.pAddressLine2 && touched.pAddressLine2 ? (
                          <Form.Text className="text-danger fs-8" as="div">
                            {errors.pAddressLine2}
                          </Form.Text>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group controlId="pAddressLine3">
                        <Form.Label className="d-block mb-2 lh-1 fw-semibold fs-7">
                          Address Line 3
                        </Form.Label>
                        <Field
                          className="form-control"
                          type="text"
                          placeholder="Address Line 3"
                          name="pAddressLine3"
                          id="pAddressLine3"
                          value={values.pAddressLine3}
                          onChange={(e) => {
                            handleAddress(values);
                            setFieldValue("pAddressLine3", e.target.value);
                          }}
                        />
                        {errors.pAddressLine3 && touched.pAddressLine3 ? (
                          <Form.Text className="text-danger fs-8" as="div">
                            {errors.pAddressLine3}
                          </Form.Text>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group controlId="pState">
                        <Form.Label className="d-block mb-2 lh-1 fw-semibold fs-7">
                          State<sup className="text-danger">*</sup>
                        </Form.Label>
                        <Select
                          styles={selectStyle}
                          name="pState"
                          id="pState"
                          placeholder="Select State"
                          options={states}
                          filterOption={handleFilterChange}
                          value={
                            countrySelected
                              ? ""
                              : states.find(
                                  (option) => option.id === values.pState
                                )
                          }
                          onChange={(selectedOption) => {
                            handleAddress(values);
                            handlePAddress(values);
                            getDistrictsData(selectedOption.id);
                            setStateSelected(true);
                            setCountrySelected(false);
                            setPTalukaNotApplicable(false);
                            setPVillageNotApplicable(false);
                            setFieldValue("pState", selectedOption.id);
                          }}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                        ></Select>
                        {errors.pState && touched.pState ? (
                          <Form.Text className="text-danger fs-8" as="div">
                            {errors.pState}
                          </Form.Text>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group controlId="pDistrict">
                        <Form.Label className="d-block mb-2 lh-1 fw-semibold fs-7">
                          District<sup className="text-danger">*</sup>
                        </Form.Label>
                        <Select
                          styles={selectStyle}
                          name="pDistrict"
                          id="pDistrict"
                          placeholder="Select District"
                          options={districts}
                          filterOption={handleFilterChange}
                          value={
                            stateSelected || countrySelected
                              ? ""
                              : districts.find(
                                  (option) => option.id === values.pDistrict
                                )
                          }
                          onChange={(selectedOption) => {
                            values.pTaluka = "";
                            values.pVillage = "";
                            handleAddress(values);
                            getTalukasData(selectedOption.id);
                            setStateSelected(false);
                            setDistrictSelected(true);
                            setCountrySelected(false);
                            setFieldValue("pDistrict", selectedOption.id);
                          }}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                        ></Select>
                        {errors.pDistrict && touched.pDistrict ? (
                          <Form.Text className="text-danger fs-8" as="div">
                            {errors.pDistrict}
                          </Form.Text>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group controlId="pTaluka">
                        <Form.Label className="d-block mb-2 lh-1 fw-semibold fs-7">
                          Taluka<sup className="text-danger">*</sup>
                        </Form.Label>
                        <Select
                          styles={selectStyle}
                          name="pTaluka"
                          id="pTaluka"
                          placeholder="Select Taluka"
                          options={talukas}
                          filterOption={handleFilterChange}
                          value={
                            stateSelected || districtSelected || countrySelected
                              ? ""
                              : talukas.find(
                                  (option) => option.id === values.pTaluka
                                )
                          }
                          onChange={(selectedOption) => {
                            values.pVillage = "";
                            handleAddress(values);
                            getVillagesData(selectedOption.id);
                            setCountrySelected(false);
                            setStateSelected(false);
                            setDistrictSelected(false);
                            setTalukaSelected(true);
                            setFieldValue("pTaluka", selectedOption.id);
                          }}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          isDisabled={pTalukaNotApplicable}
                        ></Select>
                        {errors.pTaluka && touched.pTaluka ? (
                          <Form.Text className="text-danger fs-8" as="div">
                            {errors.pTaluka}
                          </Form.Text>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group controlId="pVillage">
                        <Form.Label className="d-block mb-2 lh-1 fw-semibold fs-7">
                          Village<sup className="text-danger">*</sup>
                        </Form.Label>
                        <Select
                          styles={selectStyle}
                          name="pVillage"
                          id="pVillage"
                          placeholder="Select Village"
                          options={villages}
                          filterOption={handleFilterChange}
                          value={
                            stateSelected ||
                            districtSelected ||
                            talukaSelected ||
                            countrySelected
                              ? ""
                              : villages.find(
                                  (option) => option.id === values.pVillage
                                )
                          }
                          onChange={(selectedOption) => {
                            handleAddress(values);
                            setCountrySelected(false);
                            setStateSelected(false);
                            setDistrictSelected(false);
                            setTalukaSelected(false);
                            setFieldValue("pVillage", selectedOption.id);
                          }}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          isDisabled={pVillageNotApplicable}
                        ></Select>
                        {errors.pVillage && touched.pVillage ? (
                          <Form.Text className="text-danger fs-8" as="div">
                            {errors.pVillage}
                          </Form.Text>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group controlId="pPincode">
                        <Form.Label className="d-block mb-2 lh-1 fw-semibold fs-7">
                          Pincode<sup className="text-danger">*</sup>
                        </Form.Label>
                        <Field
                          className="form-control"
                          type="text"
                          placeholder="Pincode"
                          name="pPincode"
                          id="pPincode"
                          value={values.pPincode}
                          onChange={(e) => {
                            handleAddress(values);
                            setFieldValue("pPincode", e.target.value);
                          }}
                        />
                        {errors.pPincode && touched.pPincode ? (
                          <Form.Text className="text-danger fs-8" as="div">
                            {errors.pPincode}
                          </Form.Text>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group controlId="stdCode">
                        <Form.Label className="d-block mb-2 lh-1 fw-semibold fs-7">
                          STD Code
                        </Form.Label>
                        <Field
                          className="form-control"
                          type="text"
                          placeholder="STD Code"
                          name="stdCode"
                          id="stdCode"
                          value={values.stdCode}
                        />
                        {errors.stdCode && touched.stdCode ? (
                          <Form.Text className="text-danger fs-8" as="div">
                            {errors.stdCode}
                          </Form.Text>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group controlId="phoneNo">
                        <Form.Label className="d-block mb-2 lh-1 fw-semibold fs-7">
                          Phone No/Mobile No<sup className="text-danger">*</sup>
                        </Form.Label>
                        <Field
                          className="form-control"
                          type="text"
                          placeholder="Phone No/Mobile No"
                          name="phoneNo"
                          id="phoneNo"
                          value={values.phoneNo}
                        />
                        {errors.phoneNo && touched.phoneNo ? (
                          <Form.Text className="text-danger fs-8" as="div">
                            {errors.phoneNo}
                          </Form.Text>
                        ) : null}
                      </Form.Group>
                    </Col>
                  </Row>

                  <div className="d-flex align-items-md-center justify-content-md-between flex-column flex-md-row mb-3 mb-md-4">
                    <h3 className="fs-5 fw-normal text-primary mb-2 mb-md-0">
                      Address for Correspondence
                    </h3>
                    <Form.Check
                      type="checkbox"
                      id="same_as_permanent"
                      label="Same as Permanent Address"
                      checked={isChecked}
                      onChange={(e) => {
                        handleCheckboxChange(e, values);
                      }}
                    />
                  </div>
                  <Row className="row-gap-3 row-gap-md-4 mb-5 mb-md-6 mb-lg-7">
                    <Col xs={12} md={6}>
                      <Form.Group controlId="cAddressLine1">
                        <Form.Label className="d-block mb-2 lh-1 fw-semibold fs-7">
                          Address Line 1<sup className="text-danger">*</sup>
                        </Form.Label>
                        <Field
                          className="form-control"
                          type="text"
                          placeholder="Address Line 1"
                          name="cAddressLine1"
                          id="cAddressLine1"
                          value={values.cAddressLine1}
                          disabled={disableCAddress}
                        />
                        {errors.cAddressLine1 && touched.cAddressLine1 ? (
                          <Form.Text className="text-danger fs-8" as="div">
                            {errors.cAddressLine1}
                          </Form.Text>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group controlId="cAddressLine2">
                        <Form.Label className="d-block mb-2 lh-1 fw-semibold fs-7">
                          Address Line 2<sup className="text-danger">*</sup>
                        </Form.Label>
                        <Field
                          className="form-control"
                          type="text"
                          placeholder="Address Line 2"
                          name="cAddressLine2"
                          id="cAddressLine2"
                          value={values.cAddressLine2}
                          disabled={disableCAddress}
                        />
                        {errors.cAddressLine2 && touched.cAddressLine2 ? (
                          <Form.Text className="text-danger fs-8" as="div">
                            {errors.cAddressLine2}
                          </Form.Text>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group controlId="cAddressLine3">
                        <Form.Label className="d-block mb-2 lh-1 fw-semibold fs-7">
                          Address Line 3
                        </Form.Label>
                        <Field
                          className="form-control"
                          type="text"
                          placeholder="Address Line 3"
                          name="cAddressLine3"
                          id="cAddressLine3"
                          value={values.cAddressLine3}
                          disabled={disableCAddress}
                        />
                        {errors.cAddressLine3 && touched.cAddressLine3 ? (
                          <Form.Text className="text-danger fs-8" as="div">
                            {errors.cAddressLine3}
                          </Form.Text>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group controlId="cState">
                        <Form.Label className="d-block mb-2 lh-1 fw-semibold fs-7">
                          State<sup className="text-danger">*</sup>
                        </Form.Label>
                        <Select
                          styles={selectStyle}
                          name="cState"
                          id="cState"
                          placeholder="Select State"
                          options={states}
                          filterOption={handleFilterChange}
                          value={
                            countrySelected || isSameAddressChanged
                              ? ""
                              : states.find(
                                  (option) => option.id === values.cState
                                )
                          }
                          onChange={(selectedOption) => {
                            handleCAddress(values);
                            getCDistrictsData(selectedOption.id);
                            setCStateSelected(true);
                            setCTalukaNotApplicable(false);
                            setCountrySelected(false);
                            setIsSameAddressChanged(false);
                            setFieldValue("cState", selectedOption.id);
                          }}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          isDisabled={disableCAddress}
                        ></Select>
                        {errors.cState && touched.cState ? (
                          <Form.Text className="text-danger fs-8" as="div">
                            {errors.cState}
                          </Form.Text>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group controlId="cDistrict">
                        <Form.Label className="d-block mb-2 lh-1 fw-semibold fs-7">
                          District<sup className="text-danger">*</sup>
                        </Form.Label>
                        <Select
                          styles={selectStyle}
                          name="cDistrict"
                          id="cDistrict"
                          placeholder="Select District"
                          options={cDistricts}
                          filterOption={handleFilterChange}
                          value={
                            cStateSelected || countrySelected
                              ? ""
                              : cDistricts.find(
                                  (option) => option.id === values.cDistrict
                                )
                          }
                          onChange={(selectedOption) => {
                            values.cTaluka = "";
                            values.cVillage = "";
                            getCTalukasData(selectedOption.id);
                            setCStateSelected(false);
                            setCDistrictSelected(true);
                            setCountrySelected(false);
                            setCVillageNotApplicable(false);
                            setFieldValue("cDistrict", selectedOption.id);
                          }}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          isDisabled={disableCAddress}
                        ></Select>
                        {errors.cDistrict && touched.cDistrict ? (
                          <Form.Text className="text-danger fs-8" as="div">
                            {errors.cDistrict}
                          </Form.Text>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group controlId="cTaluka">
                        <Form.Label className="d-block mb-2 lh-1 fw-semibold fs-7">
                          Taluka<sup className="text-danger">*</sup>
                        </Form.Label>
                        <Select
                          styles={selectStyle}
                          name="cTaluka"
                          id="cTaluka"
                          placeholder="Select Taluka"
                          options={cTalukas}
                          filterOption={handleFilterChange}
                          value={
                            countrySelected ||
                            cStateSelected ||
                            cDistrictSelected ||
                            (pTalukaNotApplicable && isChecked)
                              ? ""
                              : cTalukas.find(
                                  (option) => option.id === values.cTaluka
                                )
                          }
                          onChange={(selectedOption) => {
                            values.cVillage = "";
                            getCVillagesData(selectedOption.id);
                            setCountrySelected(false);
                            setCStateSelected(false);
                            setCDistrictSelected(false);
                            setCTalukaSelected(true);
                            setFieldValue("cTaluka", selectedOption.id);
                          }}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          isDisabled={cTalukaNotApplicable || disableCAddress}
                        ></Select>
                        {errors.cTaluka && touched.cTaluka ? (
                          <Form.Text className="text-danger fs-8" as="div">
                            {errors.cTaluka}
                          </Form.Text>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group controlId="cVillage">
                        <Form.Label className="d-block mb-2 lh-1 fw-semibold fs-7">
                          Village<sup className="text-danger">*</sup>
                        </Form.Label>
                        <Select
                          styles={selectStyle}
                          name="cVillage"
                          id="cVillage"
                          placeholder="Select Village"
                          options={cVillages}
                          filterOption={handleFilterChange}
                          value={
                            countrySelected ||
                            cStateSelected ||
                            cDistrictSelected ||
                            cTalukaSelected ||
                            (cVillageNotApplicable && isChecked)
                              ? ""
                              : cVillages.find(
                                  (option) => option.id === values.cVillage
                                )
                          }
                          onChange={(selectedOption) => {
                            setCountrySelected(false);
                            setCStateSelected(false);
                            setCDistrictSelected(false);
                            setCTalukaSelected(false);
                            setFieldValue("cVillage", selectedOption.id);
                          }}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                          isDisabled={cVillageNotApplicable || disableCAddress}
                        ></Select>
                        {errors.cVillage && touched.cVillage ? (
                          <Form.Text className="text-danger fs-8" as="div">
                            {errors.cVillage}
                          </Form.Text>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group controlId="cPincode">
                        <Form.Label className="d-block mb-2 lh-1 fw-semibold fs-7">
                          Pincode<sup className="text-danger">*</sup>
                        </Form.Label>
                        <Field
                          className="form-control"
                          type="text"
                          placeholder="Pincode"
                          name="cPincode"
                          id="cPincode"
                          value={values.cPincode}
                          disabled={disableCAddress}
                        />
                        {errors.cPincode && touched.cPincode ? (
                          <Form.Text className="text-danger fs-8" as="div">
                            {errors.cPincode}
                          </Form.Text>
                        ) : null}
                      </Form.Group>
                    </Col>
                  </Row>
                  <h3 className="fs-5 fw-normal text-primary mb-3 mb-md-4">
                    Others Details
                  </h3>
                  <Row className="row-gap-3 row-gap-md-4 mb-5 mb-md-6 mb-lg-7">
                    <Col xs={12} md={6}>
                      <Form.Group controlId="isMhCandidate">
                        <Form.Label className="d-block mb-2 lh-1 fw-semibold fs-7">
                          Are you Domiciled in the State of Maharashtra?
                          <sup className="text-danger">*</sup>
                        </Form.Label>
                        <Select
                          styles={selectStyle}
                          name="isMhCandidate"
                          id="isMhCandidate"
                          placeholder=" Domiciled in the State of Maharashtra?"
                          options={boolStatus.map((status) => ({
                            value: status,
                            label: status,
                          }))}
                          value={boolStatus.find(
                            (g) => g.value === values.isMhCandidate
                          )}
                          onChange={(selectedOption) => {
                            setFieldValue(
                              "isMhCandidate",
                              selectedOption.value
                            );
                          }}
                        ></Select>
                        {errors.isMhCandidate && touched.isMhCandidate ? (
                          <Form.Text className="text-danger fs-8" as="div">
                            {errors.isMhCandidate}
                          </Form.Text>
                        ) : null}
                      </Form.Group>
                    </Col>
                    {values.isMhCandidate === "Yes" && (
                      <Col xs={12} md={6}>
                        <Form.Group controlId="isMinority">
                          <Form.Label className="d-block mb-2 lh-1 fw-semibold fs-7">
                            Minority Details<sup className="text-danger">*</sup>
                          </Form.Label>
                          <Select
                            styles={selectStyle}
                            name="isMinority"
                            id="isMinority"
                            placeholder="Select Minority Details"
                            options={boolStatus.map((status) => ({
                              value: status,
                              label: status,
                            }))}
                            value={boolStatus.find(
                              (g) => g.value === values.isMinority
                            )}
                            onChange={(selectedOption) => {
                              setFieldValue("isMinority", selectedOption.value);
                            }}
                          ></Select>
                          {errors.isMinority && touched.isMinority ? (
                            <Form.Text className="text-danger fs-8" as="div">
                              {errors.isMinority}
                            </Form.Text>
                          ) : null}
                        </Form.Group>
                      </Col>
                    )}

                    {values.isMhCandidate === "Yes" &&
                      values.isMinority === "Yes" && (
                        <Col xs={12} md={6}>
                          <Form.Group controlId="linguisticMinority">
                            <Form.Label className="d-block mb-2 lh-1 fw-semibold fs-7">
                              Linguistic Minority
                              <sup className="text-danger">*</sup>
                            </Form.Label>
                            <Select
                              styles={selectStyle}
                              name="linguisticMinority"
                              id="linguisticMinority"
                              placeholder="Select Linguistic Minority"
                              options={linguisticMinority.map((status) => ({
                                value: status,
                                label: status,
                              }))}
                              value={linguisticMinority.find(
                                (g) => g.value === values.linguisticMinority
                              )}
                              filterOption={handleFilterChange}
                              onChange={(selectedOption) => {
                                setFieldValue(
                                  "linguisticMinority",
                                  selectedOption.value
                                );
                              }}
                            ></Select>
                            {errors.linguisticMinority &&
                            touched.linguisticMinority ? (
                              <Form.Text className="text-danger fs-8" as="div">
                                {errors.linguisticMinority}
                              </Form.Text>
                            ) : null}
                          </Form.Group>
                        </Col>
                      )}

                    {values.isMhCandidate === "Yes" &&
                      values.isMinority === "Yes" && (
                        <Col xs={12} md={6}>
                          <Form.Group controlId="religiousMinority">
                            <Form.Label className="d-block mb-2 lh-1 fw-semibold fs-7">
                              Religious Minority
                              <sup className="text-danger">*</sup>
                            </Form.Label>
                            <Select
                              styles={selectStyle}
                              name="religiousMinority"
                              id="religiousMinority"
                              placeholder="Select Religious Minority"
                              options={religiousMinority.map((status) => ({
                                value: status,
                                label: status,
                              }))}
                              filterOption={handleFilterChange}
                              value={religiousMinority.find(
                                (g) => g.value === values.religiousMinority
                              )}
                              onChange={(selectedOption) => {
                                setFieldValue(
                                  "religiousMinority",
                                  selectedOption.value
                                );
                              }}
                            ></Select>
                            {errors.religiousMinority &&
                            touched.religiousMinority ? (
                              <Form.Text className="text-danger fs-8" as="div">
                                {errors.religiousMinority}
                              </Form.Text>
                            ) : null}
                          </Form.Group>
                        </Col>
                      )}
                    <Col xs={12} md={6}>
                      <Form.Group controlId="appliedForDisabilityStatus">
                        <Form.Label className="d-block mb-2 lh-1 fw-semibold fs-7">
                          Are you Person With Disability? <sup className="text-danger">*</sup>
                        </Form.Label>
                        <Select
                          styles={selectStyle}
                          name="appliedForDisabilityStatus"
                          id="appliedForDisabilityStatus"
                          placeholder="Are you Person With Disability?"
                          options={boolStatus.map((status) => ({
                            value: status,
                            label: status,
                          }))}
                          value={boolStatus.find(
                            (g) => g.value === values.appliedForDisabilityStatus
                          )}
                          onChange={(selectedOption) =>
                            setFieldValue(
                              "appliedForDisabilityStatus",
                              selectedOption.value
                            )
                          }
                        ></Select>
                        {errors.appliedForDisabilityStatus &&
                        touched.appliedForDisabilityStatus ? (
                          <Form.Text className="text-danger fs-8" as="div">
                            {errors.appliedForDisabilityStatus}
                          </Form.Text>
                        ) : null}
                      </Form.Group>
                    </Col>

                    {values.appliedForDisabilityStatus === "Yes" && (
                      <Col xs={12} md={6}>
                        <Form.Group controlId="pwdType">
                          <Form.Label className="d-block mb-2 lh-1 fw-semibold fs-7">
                            Type of Disability
                            <sup className="text-danger">*</sup>
                          </Form.Label>
                          <Select
                            styles={selectStyle}
                            name="pwdType"
                            id="pwdType"
                            placeholder="Select Type of Disability"
                            options={pwdType.map((pwdType) => ({
                              value: pwdType,
                              label: pwdType,
                            }))}
                            value={pwdType
                              .map((pwdType) => ({
                                value: pwdType,
                                label: pwdType,
                              }))
                              .find((g) => g.value === values.pwdType)}
                            onChange={(selectedOption) => {
                              setFieldValue("pwdType", selectedOption.value);
                              setFieldValue(
                                "requireScribe",
                                getScribeAndExtraTime(
                                  selectedOption.value,
                                  "requireScribe"
                                )
                              );
                              setFieldValue(
                                "requireExtraTime",
                                getScribeAndExtraTime(
                                  selectedOption.value,
                                  "requireExtraTime"
                                )
                              );
                            }}
                          ></Select>
                          {errors.pwdType && touched.pwdType ? (
                            <Form.Text className="text-danger fs-8" as="div">
                              {errors.pwdType}
                            </Form.Text>
                          ) : null}
                        </Form.Group>
                      </Col>
                    )}

                    {values.appliedForDisabilityStatus === "Yes" &&
                      values.pwdType !== null && (
                        <Col xs={12} md={6}>
                          <Form.Group controlId="requireScribe">
                            <Form.Label className="d-block mb-2 lh-1 fw-semibold fs-7">
                              Do you Require Scribe?
                            </Form.Label>
                            <Select
                              styles={selectStyle}
                              name="requireScribe"
                              id="requireScribe"
                              placeholder=" Do you Require Scribe?"
                              options={boolStatus.map((status) => ({
                                value: status,
                                label: status,
                              }))}
                              value={
                                values.requireScribe === null
                                  ? ""
                                  : boolStatus
                                      .map((status) => ({
                                        value: status,
                                        label: status,
                                      }))
                                      .find(
                                        (g) => g.value === values.requireScribe
                                      )
                              }
                              onChange={(selectedOption) => {
                                setFieldValue(
                                  "requireScribe",
                                  selectedOption.value
                                );
                              }}
                              isDisabled={requiredScribeNotApplicable}
                            ></Select>
                            {errors.requireScribe && touched.requireScribe ? (
                              <Form.Text className="text-danger fs-8" as="div">
                                {errors.requireScribe}
                              </Form.Text>
                            ) : null}
                          </Form.Group>
                        </Col>
                      )}
                    {values.appliedForDisabilityStatus === "Yes" &&
                      values.pwdType !== null && (
                        <Col xs={12} md={6}>
                          <Form.Group controlId="requireExtraTime">
                            <Form.Label className="d-block mb-2 lh-1 fw-semibold fs-7">
                              Do you Require Extra Time?
                            </Form.Label>
                            <Select
                              styles={selectStyle}
                              name="requireExtraTime"
                              id="requireExtraTime"
                              placeholder=" Do you Require Extra Time?"
                              options={boolStatus.map((status) => ({
                                value: status,
                                label: status,
                              }))}
                              value={
                                values.requireExtraTime === null
                                  ? ""
                                  : boolStatus
                                      .map((status) => ({
                                        value: status,
                                        label: status,
                                      }))
                                      .find(
                                        (g) =>
                                          g.value === values.requireExtraTime
                                      )
                              }
                              onChange={(selectedOption) =>
                                setFieldValue(
                                  "requireExtraTime",
                                  selectedOption.value
                                )
                              }
                              isDisabled={requiredExtraTimeNotApplicable}
                            ></Select>
                            {errors.requireExtraTime &&
                            touched.requireExtraTime ? (
                              <Form.Text className="text-danger fs-8" as="div">
                                {errors.requireExtraTime}
                              </Form.Text>
                            ) : null}
                          </Form.Group>
                        </Col>
                      )}

                    <Col xs={12} md={6}>
                      <Form.Group controlId="sscPassingYear">
                        <Form.Label className="d-block mb-2 lh-1 fw-semibold fs-7">
                          Select SSC/Equivalent Passing Year
                          <sup className="text-danger">*</sup>
                        </Form.Label>
                        <Select
                          styles={selectStyle}
                          name="sscPassingYear"
                          id="sscPassingYear"
                          placeholder="Select SSC/Equivalent Passing Year"
                          options={sscYears.map((status) => ({
                            value: status,
                            label: status,
                          }))}
                          value={sscYears.find(
                            (g) => g.value === values.sscPassingYear
                          )}
                          onChange={(selectedOption) => {
                            setFieldValue(
                              "sscPassingYear",
                              selectedOption.value
                            );
                          }}
                        ></Select>
                        {errors.sscPassingYear && touched.sscPassingYear ? (
                          <Form.Text className="text-danger fs-8" as="div">
                            {errors.sscPassingYear}
                          </Form.Text>
                        ) : null}
                      </Form.Group>
                    </Col>

                    <Col xs={12} md={6}>
                      <Form.Group controlId="sscTotalPercentage">
                        <Form.Label className="d-block mb-2 lh-1 fw-semibold fs-7">
                          SSC/Equivalent Total Percentage
                          <sup className="text-danger">*</sup>
                        </Form.Label>
                        <Field
                          className="form-control"
                          type="text"
                          placeholder="SSC/Equivalent Total Percentage"
                          name="sscTotalPercentage"
                          id="sscTotalPercentage"
                          value={values.sscTotalPercentage}
                          onChange={(e) => {
                            const { value } = e.target;
                            if (decimalRegex.test(value) || value === "") {
                              setFieldValue("sscTotalPercentage", value);
                            }
                          }}
                        />
                        <Form.Text>
                          Up to 2 decimal places are allowed
                        </Form.Text>
                        {errors.sscTotalPercentage &&
                        touched.sscTotalPercentage ? (
                          <Form.Text className="text-danger fs-8" as="div">
                            {errors.sscTotalPercentage}
                          </Form.Text>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group controlId="sscBoard">
                        <Form.Label className="d-block mb-2 lh-1 fw-semibold fs-7">
                          Select SSC/Equivalent Board
                          <sup className="text-danger">*</sup>
                        </Form.Label>
                        <Select
                          styles={selectStyle}
                          name="sscBoard"
                          id="sscBoard"
                          placeholder="Select SSC/Equivalent Board"
                          options={board}
                          filterOption={handleFilterChange}
                          value={board.find(
                            (option) => option.id === values.sscBoard
                          )}
                          onChange={(selectedOption) => {
                            setFieldValue("sscBoard", selectedOption.id);
                          }}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                        ></Select>
                        {errors.sscBoard && touched.sscBoard ? (
                          <Form.Text className="text-danger fs-8" as="div">
                            {errors.sscBoard}
                          </Form.Text>
                        ) : null}
                      </Form.Group>
                    </Col>
                    {values.sscBoard === 99 && (
                      <Col xs={12} md={6}>
                        <Form.Group controlId="otherSscBoard">
                          <Form.Label className="d-block mb-2 lh-1 fw-semibold fs-7">
                            Mention Other SSC/Equivalent Board
                            <sup className="text-danger">*</sup>
                          </Form.Label>
                          <Field
                            className="form-control"
                            type="text"
                            placeholder="Other SSC Board Name"
                            name="otherSscBoard"
                            id="otherSscBoard"
                            value={values.otherSscBoard}
                          />
                          {errors.otherSscBoard && touched.otherSscBoard ? (
                            <Form.Text className="text-danger fs-8" as="div">
                              {errors.otherSscBoard}
                            </Form.Text>
                          ) : null}
                        </Form.Group>
                      </Col>
                    )}
                    <Col xs={12} md={6}>
                      <Form.Group controlId="sscSchoolState">
                        <Form.Label className="d-block mb-2 lh-1 fw-semibold fs-7">
                          Select SSC School State
                          <sup className="text-danger">*</sup>
                        </Form.Label>
                        <Select
                          styles={selectStyle}
                          name="sscSchoolState"
                          id="sscSchoolState"
                          placeholder="Select SSC School State"
                          options={states}
                          filterOption={handleFilterChange}
                          value={states.find(
                            (option) => option.id === values.sscSchoolState
                          )}
                          onChange={(selectedOption) => {
                            setFieldValue("sscSchoolState", selectedOption.id);
                          }}
                          getOptionLabel={(option) => option.name}
                          getOptionValue={(option) => option.id}
                        ></Select>
                        {errors.sscSchoolState && touched.sscSchoolState ? (
                          <Form.Text className="text-danger fs-8" as="div">
                            {errors.sscSchoolState}
                          </Form.Text>
                        ) : null}
                      </Form.Group>
                    </Col>
                    <Col xs={12} md={6}>
                      <Form.Group controlId="hscPassingStatus">
                        <Form.Label className="d-block mb-2 lh-1 fw-semibold fs-7">
                          Are you Appearing/Appeared for 12th HSC/Diploma exam
                          in 2025
                          <sup className="text-danger">*</sup>
                        </Form.Label>
                        <Select
                          styles={selectStyle}
                          name="hscPassingStatus"
                          id="hscPassingStatus"
                          placeholder="Are you Appearing/Appeared for 12th HSC/Diploma exam in 2025"
                          options={boolStatus.map((status) => ({
                            value: status,
                            label: status,
                          }))}
                          value={boolStatus.find(
                            (g) => g.value === values.hscPassingStatus
                          )}
                          onChange={(selectedOption) => {
                            setFieldValue(
                              "hscPassingStatus",
                              selectedOption.value
                            );
                          }}
                        ></Select>
                        {errors.hscPassingStatus && touched.hscPassingStatus ? (
                          <Form.Text className="text-danger fs-8" as="div">
                            {errors.hscPassingStatus}
                          </Form.Text>
                        ) : null}
                      </Form.Group>
                    </Col>
                    {values.hscPassingStatus === "No" && (
                      <>
                        <Col xs={12} md={6}>
                          <Form.Group controlId="hscPassingYear">
                            <Form.Label className="d-block mb-2 lh-1 fw-semibold fs-7">
                              Select HSC/Diploma Passing Year
                              <sup className="text-danger">*</sup>
                            </Form.Label>
                            <Select
                              styles={selectStyle}
                              name="hscPassingYear"
                              id="hscPassingYear"
                              placeholder="Select HSC/Diploma Passing Year"
                              options={years.map((status) => ({
                                value: status,
                                label: status,
                              }))}
                              value={years.find(
                                (g) => g.value === values.hscPassingYear
                              )}
                              onChange={(selectedOption) => {
                                setFieldValue(
                                  "hscPassingYear",
                                  selectedOption.value
                                );
                              }}
                            ></Select>
                            {errors.hscPassingYear && touched.hscPassingYear ? (
                              <Form.Text className="text-danger fs-8" as="div">
                                {errors.hscPassingYear}
                              </Form.Text>
                            ) : null}
                          </Form.Group>
                        </Col>
                        <Col xs={12} md={6}>
                          <Form.Group controlId="hscBoard">
                            <Form.Label className="d-block mb-2 lh-1 fw-semibold fs-7">
                              Select HSC/Diploma/Equivalent Board
                              <sup className="text-danger">*</sup>
                            </Form.Label>
                            <Select
                              styles={selectStyle}
                              name="hscBoard"
                              id="hscBoard"
                              placeholder="Select HSC/Diploma/Equivalent Board"
                              options={board}
                              filterOption={handleFilterChange}
                              value={board.find(
                                (option) => option.id === values.hscBoard
                              )}
                              onChange={(selectedOption) => {
                                setFieldValue("hscBoard", selectedOption.id);
                              }}
                              getOptionLabel={(option) => option.name}
                              getOptionValue={(option) => option.id}
                            ></Select>
                            {errors.hscBoard && touched.hscBoard ? (
                              <Form.Text className="text-danger fs-8" as="div">
                                {errors.hscBoard}
                              </Form.Text>
                            ) : null}
                          </Form.Group>
                        </Col>

                        {values.hscBoard === 99 && (
                          <Col xs={12} md={6}>
                            <Form.Group controlId="otherHscBoard">
                              <Form.Label className="d-block mb-2 lh-1 fw-semibold fs-7">
                                Mention Other HSC/Diploma/Equivalent Board
                                <sup className="text-danger">*</sup>
                              </Form.Label>
                              <Field
                                name="otherHscBoard"
                                id="otherHscBoard"
                                className="form-control"
                                type="text"
                                placeholder="Other HSC/Diploma/Equivalent Board Name"
                                value={values.otherHscBoard}
                              />
                              {errors.otherHscBoard && touched.otherHscBoard ? (
                                <Form.Text
                                  className="text-danger fs-8"
                                  as="div"
                                >
                                  {errors.otherHscBoard}
                                </Form.Text>
                              ) : null}
                            </Form.Group>
                          </Col>
                        )}
                        <Col xs={12} md={6}>
                          <Form.Group controlId="hscMarksType">
                            <Form.Label className="d-block mb-2 lh-1 fw-semibold fs-7">
                              Marks Type
                              <sup className="text-danger">*</sup>
                            </Form.Label>
                            <Select
                              styles={selectStyle}
                              name="hscMarksType"
                              id="hscMarksType"
                              placeholder="Marks Type"
                              options={marksType.map((status) => ({
                                value: status,
                                label: status,
                              }))}
                              value={
                                values.hscMarksType === null
                                  ? ""
                                  : marksType
                                      .map((status) => ({
                                        value: status,
                                        label: status,
                                      }))
                                      .find(
                                        (g) => g.value === values.hscMarksType
                                      )
                              }
                              onChange={(selectedOption) => {
                                setFieldValue(
                                  "hscMarksType",
                                  selectedOption.value
                                );
                                if (
                                  selectedOption.value !== "CGPA" ||
                                  selectedOption.value === null
                                ) {
                                  setFieldValue(
                                    "hscPercentage",
                                    calculatePercentage(
                                      values,
                                      values.hscMarksObtained,
                                      values.hscMarksOutOf
                                    )
                                  );
                                }
                                disableHscPercentageField(selectedOption.value);
                              }}
                            ></Select>
                            {errors.hscMarksType && touched.hscMarksType ? (
                              <Form.Text className="text-danger fs-8" as="div">
                                {errors.hscMarksType}
                              </Form.Text>
                            ) : null}
                          </Form.Group>
                        </Col>
                        <Col xs={12} md={6}>
                          <Form.Group controlId="hscMarksObtained">
                            <Form.Label className="d-block mb-2 lh-1 fw-semibold fs-7">
                              HSC/Diploma Marks Obtained
                              <sup className="text-danger">*</sup>
                            </Form.Label>
                            <Field
                              className="form-control"
                              type="text"
                              placeholder="HSC/Diploma Marks Obtained"
                              name="hscMarksObtained"
                              id="hscMarksObtained"
                              value={values.hscMarksObtained}
                              onChange={(e) => {
                                setFieldValue(
                                  "hscMarksObtained",
                                  e.target.value
                                );
                                if (
                                  values.hscMarksType !== "CGPA" ||
                                  values.hscMarksType === null
                                ) {
                                  setFieldValue(
                                    "hscPercentage",
                                    calculatePercentage(
                                      values,
                                      e.target.value,
                                      values.hscMarksOutOf
                                    )
                                  );
                                }
                              }}
                            />
                            {errors.hscMarksObtained &&
                            touched.hscMarksObtained ? (
                              <Form.Text className="text-danger fs-8" as="div">
                                {errors.hscMarksObtained}
                              </Form.Text>
                            ) : null}
                          </Form.Group>
                        </Col>
                        <Col xs={12} md={6}>
                          <Form.Group controlId="hscMarksOutOf">
                            <Form.Label className="d-block mb-2 lh-1 fw-semibold fs-7">
                              HSC/Diploma Marks Out Of
                              <sup className="text-danger">*</sup>
                            </Form.Label>
                            <Field
                              className="form-control"
                              type="text"
                              placeholder="HSC/Diploma Marks Out Of"
                              name="hscMarksOutOf"
                              id="hscMarksOutOf"
                              value={values.hscMarksOutOf}
                              onChange={(e) => {
                                setFieldValue("hscMarksOutOf", e.target.value);
                                if (
                                  values.hscMarksType !== "CGPA" ||
                                  values.hscMarksType === null
                                ) {
                                  setFieldValue(
                                    "hscPercentage",
                                    calculatePercentage(
                                      values,
                                      values.hscMarksObtained,
                                      e.target.value
                                    )
                                  );
                                }
                              }}
                            />
                            {errors.hscMarksOutOf && touched.hscMarksOutOf ? (
                              <Form.Text className="text-danger fs-8" as="div">
                                {errors.hscMarksOutOf}
                              </Form.Text>
                            ) : null}
                          </Form.Group>
                        </Col>
                        <Col xs={12} md={6}>
                          <Form.Group controlId="hscPercentage">
                            <Form.Label className="d-block mb-2 lh-1 fw-semibold fs-7">
                              HSC/Diploma Calculated/Equivalent Percentage
                              <sup className="text-danger">*</sup>
                            </Form.Label>
                            <Field
                              className="form-control"
                              type="text"
                              placeholder="HSC/Diploma Calculated/Equivalent Percentage"
                              name="hscPercentage"
                              id="hscPercentage"
                              value={values.hscPercentage}
                              disabled={hscPercentageDisabled}
                              onChange={(e) => {
                                const { value } = e.target;
                                if (decimalRegex.test(value) || value === "") {
                                  setFieldValue("hscPercentage", value);
                                }
                              }}
                            />
                            {!hscPercentageDisabled && (
                              <Form.Text>
                                Up to 2 decimal places are allowed
                              </Form.Text>
                            )}
                            {errors.hscPercentage && touched.hscPercentage ? (
                              <Form.Text
                                className="text-danger fs-8 mt-0"
                                as="div"
                              >
                                {errors.hscPercentage}
                              </Form.Text>
                            ) : null}
                          </Form.Group>
                        </Col>
                      </>
                    )}
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    className="submit-button"
                    type="submit"
                    variant="primary"
                    onClick={handleSubmit}
                  >
                    Save Changes
                  </Button>
                </Modal.Footer>
              </Modal>
            </Form>
          );
        }}
      </Formik>
    </>
  );
}
